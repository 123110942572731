import { Injectable } from "@angular/core";
import { ApiService } from "~/app/shared/api.service";
import { ResponseFromApi } from "~/models/textToSpeech/textToSpeech.model";
import {
  Colleague,
  Project,
  ProjectsDto,
} from "~/models/textToSpeech/project.model";
import { BehaviorSubject, Observable } from "rxjs";
import { createMD5 } from "hash-wasm";
import { FileUploadContext } from "./pages/create-project/create-project.component";
import { RecordingRegion } from "@models/shared/recordingRegion.model";

type IHasher = any; // see 'hash-wasm/dist/lib/WASMInterface';

@Injectable({
  providedIn: "root",
})
export class ProjectService {
  private fileUploadContexts = new BehaviorSubject([]);
  public chunkSize = 64 * 1024 * 1024;

  constructor(private api: ApiService) {}

  public save(projectsDto: ProjectsDto): Observable<ResponseFromApi> {
    return this.api.post("project/save", projectsDto);
  }

  // Here updating only one project so 'updateProject'
  public update(projectDto: ProjectsDto): Observable<ResponseFromApi> {
    return this.api.post("project/update", projectDto);
  }

  // Here updating project assets
  public updateProjectAssets(projectAssets: any): Observable<ResponseFromApi> {
    return this.api.post("project/updateProjectAssets", projectAssets);
  }

  public getByUserId(): Observable<Project[]> {
    return this.api.get("project/getByUserId");
  }

  public getById(id: number): Observable<Project> {
    return this.api.get("project/getById?id=" + id);
  }

  public getByUniqueProjectName(
    uniqueProjectName: string
  ): Observable<Project> {
    return this.api.get(
      "project/getByUniqueProjectName?uniqueProjectName=" + uniqueProjectName
    );
  }

  public delete(uniqueProjectName: string): Observable<ResponseFromApi> {
    return this.api.get(
      "project/delete?uniqueProjectName=" + uniqueProjectName
    );
  }

  public batchDelete(uniqueProjectNames: string): Observable<void> {
    return this.api.post("project/batchDelete", uniqueProjectNames);
  }

  public getLexiconsByLanguageAndRegion(
    languageRegion: string
  ): Observable<any> {
    return this.api.get(
      "lexicon/getByLanguageAndRegion?languageRegion=" + languageRegion
    );
  }

  public onChangeFileUploadContexts(fileUploadContexts: FileUploadContext[]) {
    this.fileUploadContexts.next(fileUploadContexts);
  }

  public getFileUploadContexts(): BehaviorSubject<FileUploadContext[]> {
    return this.fileUploadContexts;
  }

  public async computeFileMd5checksum(file: File): Promise<string> {
    const hasher = await createMD5();
    const fileReader = new FileReader();
    const chunkNumber = Math.floor(file.size / this.chunkSize);

    for (let i = 0; i <= chunkNumber; i++) {
      const chunk = file.slice(
        this.chunkSize * i,
        Math.min(this.chunkSize * (i + 1), file.size)
      );
      await this.hashChunk(chunk, fileReader, hasher);
    }

    const hash = hasher.digest();
    return Promise.resolve(hash);
  }

  public hashChunk(
    chunk: Blob,
    fileReader: FileReader,
    hasher: IHasher
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      fileReader.onload = async (progressEvent: ProgressEvent) => {
        const view = new Uint8Array((progressEvent.target as any).result);
        hasher.update(view);
        resolve();
      };
      fileReader.readAsArrayBuffer(chunk);
    });
  }

  public discardFile(assetIds: string[]): Observable<ResponseFromApi> {
    return this.api.post("project/removeFilesFromS3", assetIds);
  }

  public createPreviewAudio(projectDto: ProjectsDto): Observable<string> {
    return this.api.post("textToSpeech/createPreview", projectDto);
  }

  public updateEditingBy(
    uniqueProjectName: string,
    key: string
  ): Observable<any> {
    return this.api.get(`project/updateEditingBy/${uniqueProjectName}/${key}`);
  }

  public getColleague(): Observable<Colleague[]> {
    return this.api.get("project/getColleague");
  }

  public shareProjectForVoicer(
    userId: number,
    projectId: string
  ): Observable<any> {
    const shareDetails = {
      userId: userId,
      projectId: projectId,
    };
    return this.api.post("project/share", shareDetails);
  }

  public clipRecordingRegions(
    recordingRegion: RecordingRegion
  ): Observable<any> {
    return this.api.post("project/clipRecordingRegions", recordingRegion);
  }
}
