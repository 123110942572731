export enum ProjectStatus {
    UploadingText = 'Uploading text',
    UploadSuccess = 'Uploaded successfully',
    UpdateSuccess = 'Updated successfully',
    DeleteSuccess = 'Deleted successfully',
    UploadFailed = 'Upload failed',
    DeleteFailed = 'Delete failed',
    SSMLCreation = 'SSML creation',
    SyntheticVoiceGeneration = 'Synthetic voice generation',
    RetakesToPerform = 'Retakes to perform',
    VdCreated = 'VD created',
    AudioTrackCreation = 'Audio track creation',
    Mixing = 'Mixing',
    Assembling = 'Assembling',
    ReadyToDownload = 'Ready to download',
    Restart = 'Restarted',
    Success = 'Success',
    NoDataFound = 'No Data Found',
    ForMattingError = 'Formatting error',
    StartTimeCodeError = 'StartTimeCode error' /* can change to a proper error statement. */,
    WaitForTTML = 'Waiting for ttml',
    ScriptCreated = 'Script created'
}

export enum ReplyStatus {
    Uploading = 'Uploading',
    Creating = 'Creating',
    Ready = 'Ready',
    Error = 'Error',
}

export enum VoiceType {
    Synthetic = 'synthetic',
    Voicer = 'voicer',
}

/* the Dto object  which is from UI */
export interface ProjectsDto {
    id?: number;
    userId?: number;
    tenantId?: string;
    projectNames: string[];
    uniqueProjectNames: string[];
    language: string;
    region: string;
    voice: string;
    lexiconUri: string;
    startTimeCode: string;
    speedRate: number;
    usersToNotify: any;
    isRestarting: boolean;
    isAutomatedSpeed: boolean;
    automatedSpeedRate: number;
    fileDetails?: ProjectFileDetails[];
    replies?: ProjectReply[];
    frameRate?: number;
    isAutomatedMix?: boolean;
    previewAudio?: string;
    stereoFilePath?: string;
    vdFilepath?: string;
    audioFilePath?: string;
    projectStatus?: string;
    clientId?: number;
    templateId?: number;
    lexiconId?: number;
    voiceRecordingEnabled?: boolean;
    humanVoiceRecorderId?: string;
    ttmlFilePath?: string;
    isUpdateXml?: boolean;
    startVd?: boolean;
    voiceSetting?: string;
    voicerReadingSpeed?: number;
    videoAssetId?: string;
    audioAssetId?: string;
    automatedSoundCorrection?: boolean;
    isGeneratedTtmlFile?: boolean;
    isManualMix?: boolean;
}

/* the Dto object  which is from service to DB.(project table) */
export interface Project {
    id?: number;
    name: string;
    userEmail?: string;
    userName?: string;
    uniqueProjectName: string;
    userId: number;
    tenantId?: string;
    language: string;
    region: string;
    voice: string;
    lexiconUri: string;
    startTimeCode: string;
    speedRate: number;
    frameRate: number;
    isAutomatedSpeedRate: boolean;
    isAutomatedMix: boolean;
    automatedSpeedRate: number;
    ttmlFilePath: string;
    audioFilePath: string;
    stereoFilePath: string;
    vdFilePath: string;
    longAudioFilePaths: string; // as sql returns the string
    status: string;
    errorMessage: string;
    usersToNotify?: any; // as sql returns the string
    fileDetails: ProjectFileDetails[];
    replies?: ProjectReply[];
    editingBy?: string;
    isSelected?: boolean;
    creationDateTime: Date;
    lastModificationDateTime: Date;
    clientId?: number;
    templateId?: number;
    createdBy?: string;
    clientName?: string;
    lexiconId?: number;
    lexiconName?: string;
    lexiconLanguage?: string;
    humanVoiceRecorderId?: string;
    voiceSetting?: string;
    voicerReadingSpeed?: number;
    videoAssetId?: string;
    audioAssetId?: string;
    templateName?: string;
    sharedWith?: number;
    automatedSoundCorrection?: boolean;
    isGeneratedTtmlFile?: boolean;
}

export class ProjectData {
    projectName = '';
    uniqueProjectName = '';
    ttmlFileContent: any;
    tempAudioFilePath = '';
    basicSpeedRate: number | undefined;
    segmentData?: string[] = [];
    ssmlSegments: string[] = [];
    audioFileNames: string[] = [];
    longAudioFileNames: string[] = [];
    maxDurationInSec: number[] = [];
    tcInInSecondsList: number[] = [];
    previewAudio?: string;
}

export interface ProjectFileDetails {
    uniqueProjectName: string;
    files: {
        ttmlFilePath: string | null;
        stereoFilePath: string | null;
        isAssetDropBox?: boolean;
    };
}

export interface ProjectReply {
    id?: number;
    userId?: number;
    projectId: number;
    uniqueProjectName: string;
    tcIn: string;
    tcOut: string;
    nextTcIn?: string;
    ttmlSegmentData: string;
    speedRate?: number;
    audioFilePath?: string;
    isLongAudio: boolean;
    isFarTooLongAudio: boolean;
    longAudioDuration?: number;
    status?: string;
    error?: string;
    contentData?: any;
    voiceRecording?: any;
    isUpdateXml?: boolean;
    isSelected?: boolean;
    isRecordingBeingPlayed?: boolean;
    multipleRetakes?: string | string[];
    selectedRetakeIndex?: number;
}

export interface Colleague {
    id: number;
    name: string;
}

export interface ShareProjectDetails {
    uniqueProjectName: string;
    sharedWith: number;
}
