import { debounceTime, map, tap } from "rxjs/operators";
import { Component, HostListener, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationStart, Router } from "@angular/router";
import { Project, ProjectStatus } from "@models/textToSpeech/project.model";
import { TranslateService } from "@ngx-translate/core";
import { filter } from "rxjs/internal/operators/filter";
import { UserHelperService } from "~/app/shared/userHelper.service";
import { VideoJSDataService } from "~/app/shared/video-jsdata.service";
import { ProjectService } from "~/app/textToSpeech/project.service";
import { environment } from "~/environments/environment";
import { ConnectWebSocketService } from "../../connectWebSocket.service";
import { FormPageService } from "~/app/base/formPage.service";
import { CatalogAssetSelectionService } from "~/app/shared/catalog-assets-selection.service";
import { Location } from "@angular/common";

@Component({
  selector: "app-project-details",
  templateUrl: "./project-details.component.html",
  styleUrls: ["./project-details.component.scss"],
})
export class ProjectDetailsComponent implements OnInit, OnDestroy {
  public selectedTabIndex = 0;
  public projectId: number;
  public project: Project;
  public userId: number;
  public editingByUser: string[] = [];
  public errorMessage: string;
  private routeChange: any;
  public pageId: number;
  public tabIndex: number;
  public initRepliesLength: number;

  constructor(
    private projectService: ProjectService,
    private activateRoute: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService,
    private videoJSService: VideoJSDataService,
    private connectWebSocketService: ConnectWebSocketService,
    private formPageService: FormPageService,
    private assetSelectionService: CatalogAssetSelectionService,
    private _location: Location
  ) {
    this.activateRoute.params.subscribe((parameters) => {
      this.projectId = parameters.id;
    });
    this.pageId = this.router.getCurrentNavigation().extras.state?.pageId;
    this.tabIndex = this.router.getCurrentNavigation().extras.state?.tabIndex;
    this._location.replaceState(location.pathname);
  }

  @HostListener("window:unload", ["$event"])
  onunload($event: Event) {
    if (!this.isNotEditable()) {
      this.connectWebSocketService.sendUserMessage({
        uniqueProjectName: this.project.uniqueProjectName,
      } as Project);
    }
  }

  @HostListener("window:beforeunload", ["$event"])
  beforeunload($event: BeforeUnloadEvent) {
    $event.preventDefault();
    $event.returnValue = "";
  }

  @HostListener("window:popstate", ["$event"])
  onPopState(event: any) {
    this.videoJSService.updateVisibility(false);
  }

  ngOnInit() {
    this.getProjectDetails();
    this.routeChange = this.router.events
      .pipe(
        filter(
          (event) => event instanceof NavigationStart && !this.isNotEditable()
        ),
        debounceTime(1000)
      )
      .subscribe(() => {
        this.updateEditingBy(this.project.uniqueProjectName);
      });
    this.handleSocket();
  }

  ngOnDestroy() {
    this.routeChange.unsubscribe();
    this.connectWebSocketService.socketMessages.unsubscribe();
  }

  handleSocket() {
    this.connectWebSocketService.connectToProjectSocket();
    this.connectWebSocketService.connectToUserSocket();

    this.connectWebSocketService.socketMessages.subscribe(
      (updatedProject: string) => {
        if (!this.project) {
          return;
        }
        const statusUpdatedProject = JSON.parse(updatedProject as string);
        if (
          this.project.uniqueProjectName ===
          statusUpdatedProject.uniqueProjectName
        ) {
          this.project.status = statusUpdatedProject.status;
          this.project.audioFilePath = statusUpdatedProject.audioFilePath;
        }
      }
    );
  }

  getProjectDetails() {
    this.projectService.getById(this.projectId).subscribe((data) => {
      this.project = data;
      this.initRepliesLength = this.project.replies.length;
      this.project.lexiconUri = !this.project.lexiconUri
        ? " "
        : this.project.lexiconUri;
      this.userId = UserHelperService.getUserIdFromLocalStorage();
      this.editingByUser = this.project.editingBy
        ? this.project.editingBy!.split("|")
        : [];
      if (this.isNotEditable()) {
        alert(this.errorMessage);
      }
      this.getTabIndexFromStorage();
      if (this.tabIndex) {
        this.selectedTabIndex = this.tabIndex;
      } else if (this.pageId !== undefined) {
        this.selectedTabIndex = 1;
      }
    });
  }

  updateEditingBy(uniqueProjectName: string) {
    this.projectService.updateEditingBy(uniqueProjectName, "exit").subscribe();
  }

  navigateToProjects() {
    this.videoJSService.updateVisibility(false);
    this.router.navigateByUrl("/projects");
  }

  hideWavTab() {
    return this.initRepliesLength > 0;
  }

  isNotEditable() {
    return false;
    const isOtherUserEditing =
      this.project &&
      this.project.editingBy &&
      this.userId &&
      this.userId !== Number(this.editingByUser[0]);
    const editingByUserError = this.translateService
      .instant("createProject.editingByUserError")
      .replace("{userName}", this.editingByUser[1]);
    this.errorMessage = isOtherUserEditing ? editingByUserError : "";
    return isOtherUserEditing;
  }

  private getTabIndexFromStorage(): void {
    this.formPageService
      .shouldAutoRestore(this.activateRoute.queryParams)
      .pipe(
        filter((isAutoRestore: boolean) => isAutoRestore),
        map(() => JSON.parse(sessionStorage.getItem("draftProjectData"))),
        tap((draftData) => {
          this.selectedTabIndex = draftData?.selectedTabIndex || 0;
          // also we need to map other details from storage
          this.setProjectDetailsFromStorage(draftData);
        })
      )
      .subscribe();
  }

  private setProjectDetailsFromStorage(detailsFromStorage: any): void {
    const assets = this.assetSelectionService.getSelection()?.assets || [];
    if (assets.length) {
      const videoAsset = assets.find((asset) => asset.assetType === "video");
      const audioAsset = assets.find((asset) => asset.assetType === "audio");
      this.project.videoAssetId = videoAsset?.id;
      this.project.audioAssetId = audioAsset?.id;
    }
    const {
      projectName,
      voiceSettingType,
      language,
      region,
      voice,
      voicerReadingSpeed,
      startTimeCode,
      frameRate,
      speed,
      automatedSpeedRate,
      automatedSoundCorrection,
    } = detailsFromStorage;

    Object.assign(this.project, {
      name: projectName,
      voiceSetting: voiceSettingType,
      language,
      region,
      voice,
      voicerReadingSpeed,
      startTimeCode,
      frameRate,
      speedRate: speed,
      automatedSpeedRate,
      automatedSoundCorrection,
    });
  }
}
